import React from 'react'

import { StyledHeader, AppLogo } from './styled'

const Header = props => {
  return (
    <StyledHeader>
      <AppLogo src="/assets/img/logo.png" />
    </StyledHeader>
  )
}

export default Header
