import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from 'Containers/Home'
import Page404 from 'Containers/404'
import Header from 'Components/Header'
import Footer from 'Components/Footer'
import { WhiteLayout, PaddedContent } from 'Common/styled'

class App extends Component {
  render() {
    return (
      <WhiteLayout>
        <Header />
        <PaddedContent>
          <Switch>
            <Route component={Home} exact path={'/'} />
            <Route component={Page404} />
          </Switch>
        </PaddedContent>
        <Footer />
      </WhiteLayout>
    )
  }
}
export default App
