import axios from 'axios'
import config from './config'

export const API = axios.create({
  baseURL: config.API_URL,
  headers: {
    api_key: '2D833317-6DD9-4216-9B2C-12B4676EB317',
  },
})
export const LEGACY_API = axios.create({
  baseURL: config.LEGACY_API_URL,
  headers: {
    api_key: '2D833317-6DD9-4216-9B2C-12B4676EB317',
  },
})
