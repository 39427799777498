import { notification } from 'antd'

export const getQueryParam = param => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param)
}
export const handleServerError = error => {
  const status = error.response && error.response.status
  const data = error.response && error.response.data
  return notification.error({
    message: status ? `Errore ${status}` : 'Si è verificato un errore',
    description: data && data.message ? data.message : 'Riprova',
  })
}
