const env = process.env.REACT_APP_ENV
const envConfig = {
  dev: {
    API_URL:
      'https://test-hubpay-backend-customer-k34tqjimta-ew.a.run.app/api/v1',
    LEGACY_API_URL:
      'https://lmit-hubpay-customers-test-qqgzuveaua-ew.a.run.app/api/v1',
  },
  test: {
    API_URL:
      'https://test-hubpay-backend-customer-k34tqjimta-ew.a.run.app/api/v1',
    LEGACY_API_URL:
      'https://lmit-hubpay-customers-test-qqgzuveaua-ew.a.run.app/api/v1',
  },
  prod: {
    API_URL:
      'https://prod-hubpay-backend-customer-ureoex5xga-ew.a.run.app/api/v1',
    LEGACY_API_URL:
      'https://lmit-hubpay-customers-prod-qqgzuveaua-ew.a.run.app/api/v1',
  },
}

export default envConfig[env]
