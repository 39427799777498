import { API, LEGACY_API } from './axiosConfig'

export default {
  order: {
    retrieve: uuid => API.get('/orders/retrieve', { params: { uuid } }),
    retrieveLegacy: uuid =>
      LEGACY_API.get('/orders/retrieve', { params: { uuid } }),
    acceptConditions: ({ uuid, ...conditions }) =>
      API.post('/phonesale/acceptConditions', { uuid, ...conditions }),
  },
}
