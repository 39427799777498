import { GET_ORDER, ACCEPT_CONDITIONS } from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const getOrder = uuid => async dispatch => {
  try {
    const res = await api.order.retrieve(uuid)
    if (res.status === 204) return { status: res.status }
    const { info, products } = res.data.order
    return dispatch({ type: GET_ORDER, payload: { order: info, products } })
  } catch (error) {
    const { status } = error.response
    if (status === 404) return { status }
    return handleServerError(error)
  }
}
export const getOrderLegacy = uuid => async dispatch => {
  try {
    const res = await api.order.retrieveLegacy(uuid)
    if (res.status === 204) return { status: res.status }
    const { info, products } = res.data.order
    return dispatch({ type: GET_ORDER, payload: { order: info, products } })
  } catch (error) {
    const { status } = error.response
    if (status === 404) return { status }
    return handleServerError(error)
  }
}

export const acceptConditions =
  ({ uuid, ...conditions }) =>
  async dispatch => {
    try {
      const res = await api.order.acceptConditions({ uuid, ...conditions })
      const { status, data } = res
      dispatch({ type: ACCEPT_CONDITIONS })
      return { status, paymentLink: data.paymentRequestLink }
    } catch (error) {
      const { status } = error.response
      handleServerError(error)
      return { status }
    }
  }
