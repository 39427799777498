import styled from 'styled-components'
import { Layout, Button } from 'antd'

import { colors, device } from 'Common/constants'

const getContentWidth = device => {
  switch (device) {
    case 'desktop':
      return '800px'
    case 'mobile':
    default:
      return '100%'
  }
}

export const WhiteLayout = styled(Layout)`
  background: ${colors.white};
`
export const PaddedContent = styled(Layout.Content)`
  width: ${getContentWidth(device)};
  margin: auto;
  padding: 16px;
  min-height: calc(100vh - 96px);
  display: grid;
  gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, min-content));
`
export const UppercaseButton = styled(Button)`
  text-transform: uppercase;
`
