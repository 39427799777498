import styled from 'styled-components'
import { Form } from 'antd'

import { colors } from 'Common/constants'

export const FormItem = styled(Form.Item)`
  & label {
    color: ${colors.grey4};
    font-weight: 500;
    text-transform: uppercase;
  }
`
export const SubmitWrapper = styled.div`
  display: grid;
  justify-content: end;
`
