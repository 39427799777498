import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'antd'
import ProductsList from 'Components/ProductsList'
import { TotalWrapper, Subtotal, Discount, Total, Payment } from './styled'

const OrderCard = props => {
  const { info, products, device } = props
  const {
    fullOrderNumber,
    grossTotal,
    finalTotal,
    discountValue,
    checkoutDepositAmount,
  } = info
  const hasDiscount = grossTotal !== finalTotal
  const discount = discountValue || Math.ceil(((grossTotal - finalTotal) / grossTotal) * 100)
  return (
    <Card>
      <h1>{`Ordine n. ${fullOrderNumber}`}</h1>
      <ProductsList products={products} device={device} />
      <TotalWrapper device={device}>
        {hasDiscount ? (
          <>
            <Subtotal>
              {'Totale senza sconti: '}
              <strong>{`${grossTotal?.toFixed(2)}€`}</strong>
            </Subtotal>
            <Discount>
              {'Percentuale di sconto: '}
              <strong>{`${discount}%`}</strong>
            </Discount>
          </>
        ) : null}
        <Total>
          {'Totale: '}
          <strong>{`${finalTotal?.toFixed(2)}€`}</strong>
        </Total>
        {checkoutDepositAmount > 0 ? (
          <Payment>
            {'Importo pagato: '}
            <strong>{`${checkoutDepositAmount?.toFixed(2)}€`}</strong>
          </Payment>
        ) : null}
      </TotalWrapper>
    </Card>
  )
}
OrderCard.propTypes = {
  info: PropTypes.object,
  products: PropTypes.arrayOf(PropTypes.object),
}
export default OrderCard
