import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Result, Divider, Alert, Card } from 'antd'
import { connect } from 'react-redux'

import {
  getOrder,
  getOrderLegacy,
  acceptConditions
} from 'Redux/actions/app-actions'

import Loader from 'Components/Loader'
import OrderCard from 'Components/OrderCard'
import ConditionsForm from 'Components/ConditionsForm'

import { getQueryParam } from 'Common/utils'
import Button from 'Components/Button'

import {
  bankTransferInfoStatuses,
  device,
  HUBPAY_STATUS_SEND_PAYMENT_LINK_DONE,
  HUBPAY_STATUS_SENT_TO_CUSTOMER,
  PAYMENT_METHOD_BANKTRANSFER
} from 'Common/constants'

class Home extends Component {
  static propTypes = {
    acceptConditions: PropTypes.func,
    getOrder: PropTypes.func,
    order: PropTypes.object,
    products: PropTypes.arrayOf(PropTypes.object)
  }

  state = {
    isLoading: true,
    formValues: {}
  }

  componentDidMount() {
    const uuid = getQueryParam('uuid')
    if (uuid) {
      this.getOrder(uuid)
    } else {
      this.setState({ isLoading: false, hasError: true })
    }
  }

  getOrder = async uuid => {
    const { getOrder, getOrderLegacy } = this.props
    const res = await getOrder(uuid)
    const newState = { isLoading: false, formValues: { uuid } }
    if (res.status === 204) {
      const res2 = await getOrderLegacy(uuid)
      if (res2.status === 204) newState.hasError = true
    }
    this.setState(newState)
  }

  handleFormChange = changedValues => {
    this.setState(prevState => ({
      formValues: { ...prevState.formValues, ...changedValues }
    }))
  }

  handleFormSubmit = () => {
    const { formValues } = this.state
    const { acceptConditions } = this.props
    this.setState({ isSubmitLoading: true })
    acceptConditions(formValues).then(res => {
      if (res.paymentLink) {
        window.location.href = res.paymentLink
      } else {
        this.setState({ isSubmitLoading: false })
      }
    })
  }

  renderBankTransferInfo() {
    const { order } = this.props
    return (
      <>
        <Alert
          banner
          type={'info'}
          message={
            'Procedi al pagamento effettuando un bonifico alle coordinate seguenti:'
          }
        />
        <Card>
          <span>
            <strong>{'Intestatario:'}</strong> {order.payableTo}
          </span>
          <br />
          <span>
            <strong>{'Banca:'}</strong> {order.bankName}
          </span>
          <br />
          <span>
            <strong>{'IBAN:'}</strong> {order.iban}
          </span>
          <br />
          <span>
            <strong>{'Codice Fiscale LMIT:'}</strong> {order.vatCode}
          </span>
          <br />
          <span>
            <strong>{'Causale bonifico ordinario:'}</strong>{' '}
            {`Saldo ordine n. ${order.fullOrderNumber}`}
          </span>
          <br />
          <span>
            <strong>{'Causale bonifico parlante:'}</strong>{' '}
            {`Saldo ordine n. ${order.fullOrderNumber} (Inserire anche SCIA/CILA di riferimento)`}
          </span>
        </Card>
      </>
    )
  }

  renderConditions() {
    const { isSubmitLoading, formValues } = this.state
    const { order } = this.props
    const { conditions, hubPayStatus } = order
    const isSubmitEnabled = conditions.every(c => formValues[c.key])
    const isConditionEnabled =
      hubPayStatus === HUBPAY_STATUS_SENT_TO_CUSTOMER ||
      hubPayStatus === HUBPAY_STATUS_SEND_PAYMENT_LINK_DONE

    return (
      <>
        <Divider />
        {!isConditionEnabled ? (
          <Alert
            banner
            type={'success'}
            message={'Le condizioni sono già state accettate'}
          />
        ) : null}
        <ConditionsForm
          isEnabled={isConditionEnabled}
          conditions={conditions}
          onChangeCallback={this.handleFormChange}
        />
        {isConditionEnabled ? (
          <Button
            loading={isSubmitLoading}
            disabled={!isSubmitEnabled}
            onClick={this.handleFormSubmit}
            style={{ justifySelf: 'end' }}>
            {'Procedi al pagamento'}
          </Button>
        ) : null}
      </>
    )
  }

  render() {
    const { isLoading, hasError } = this.state
    const { order, products } = this.props
    const { customerFirstname, conditions, hubPayStatus, paymentMethod } = order
    const isBankTransferToPay =
      paymentMethod === PAYMENT_METHOD_BANKTRANSFER &&
      bankTransferInfoStatuses.includes(hubPayStatus)

    if (isLoading) return <Loader />
    if (hasError)
      return (
        <Result
          status={'404'}
          title={"L'ordine selezionato non è disponibile"}
        />
      )
    return (
      <>
        <p>
          {'Ciao '}
          <strong>{customerFirstname}</strong>
          {','}
          <br />
          {'Ti ringraziamo per aver scelto Leroy Merlin.'}
          <br />
          {'Di seguito, un riepilogo del tuo ordine'}
        </p>
        <OrderCard info={order} products={products} device={device} />
        {isBankTransferToPay && this.renderBankTransferInfo()}
        {conditions ? this.renderConditions() : null}
      </>
    )
  }
}

const mapStateToProps = state => ({
  order: state.app.order,
  products: state.app.products
})
const mapDispatchToProps = {
  getOrder,
  getOrderLegacy,
  acceptConditions
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)
