import { GET_ORDER } from '../action-types'

export const initialState = {
  order: {},
}
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ORDER:
      return { ...state, ...payload }
    default:
      return state
  }
}
