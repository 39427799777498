import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'

import { StyledLoader } from './styled'

const Loader = props => {
  const { height } = props
  return (
    <StyledLoader
      height={height}
      indicator={<Icon type="loading-3-quarters" spin />}
    />
  )
}
Loader.propTypes = {
  height: PropTypes.string,
}
export default Loader
