import styled from 'styled-components'
import { Layout } from 'antd'
import { colors } from 'Common/constants'

export const HeaderBar = styled.div`
  height: 16px;
  background-color: ${colors.main};
`
export const StyledHeader = styled(Layout.Header)`
  background-color: ${colors.white};
  text-align: center;
  padding: 0 16px;
  z-index: 999;
`
export const AppLogo = styled.img`
  height: 32px;
`
