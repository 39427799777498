import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Radio } from 'antd'

import { FormItem } from './styled'

const ConditionsForm = Form.create({
  onValuesChange(props, changedValues, allValues) {
    props.onChangeCallback(changedValues)
  },
})(props => {
  const { form, conditions, isEnabled } = props
  return (
    <Form>
      {conditions.map(condition => {
        const { key, title, text } = condition
        return (
          <FormItem label={title} key={key}>
            <Input.TextArea
              value={text}
              autoSize={{ minRows: 2, maxRows: 6 }}
              disabled
            />
            {form.getFieldDecorator(key, {
              initialValue: !isEnabled ? true : undefined,
            })(
              <Radio.Group disabled={!isEnabled}>
                <Radio value={true}>{'Accetto'}</Radio>
                <Radio value={false}>{'Non accetto'}</Radio>
              </Radio.Group>
            )}
          </FormItem>
        )
      })}
    </Form>
  )
})
ConditionsForm.propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.object),
  isEnabled: PropTypes.bool,
}
export default ConditionsForm
