export const device = window.innerWidth < 800 ? 'mobile' : 'desktop'

export const PAYMENT_METHOD_BANKTRANSFER = 'BONIFICO BANCARIO'

export const HUBPAY_STATUS_SENT_TO_CUSTOMER = 'SENT_TO_CUSTOMER'
export const HUBPAY_STATUS_SEND_PAYMENT_LINK_DONE = 'SEND_PAYMENT_LINK_DONE'
export const HUBPAY_STATUS_CONDITIONS_ACCEPTED = 'CONDITIONS_ACCEPTED'
export const HUBPAY_STATUS_PAYMENT_DONE = 'PAYMENT_DONE'
export const HUBPAY_STATUS_PAYMENT_FAIL = 'PAYMENT_FAIL'
export const HUBPAY_STATUS_RECEIPT_PENDING = 'RECEIPT_PENDING'
export const HUBPAY_STATUS_RECEIPT_DONE = 'RECEIPT_DONE'
export const HUBPAY_STATUS_RECEIPT_FAIL = 'RECEIPT_FAIL'
export const HUBPAY_STATUS_MANUAL_RECEIPT_DONE = 'MANUAL_RECEIPT_DONE'
export const HUBPAY_STATUS_SEND_RECEIPT_DONE = 'SEND_RECEIPT_DONE'
export const HUBPAY_STATUS_SEND_RECEIPT_FAIL = 'SEND_RECEIPT_FAIL'
export const HUBPAY_STATUS_COMPLETED = 'COMPLETED'
export const HUBPAY_STATUS_CANCELLED = 'CANCELLED'

export const bankTransferInfoStatuses = [
  HUBPAY_STATUS_CONDITIONS_ACCEPTED,
  HUBPAY_STATUS_PAYMENT_DONE,
  HUBPAY_STATUS_PAYMENT_FAIL,
  HUBPAY_STATUS_RECEIPT_PENDING,
  HUBPAY_STATUS_RECEIPT_DONE,
  HUBPAY_STATUS_RECEIPT_FAIL,
  HUBPAY_STATUS_MANUAL_RECEIPT_DONE,
  HUBPAY_STATUS_SEND_RECEIPT_DONE,
  HUBPAY_STATUS_SEND_RECEIPT_FAIL,
  HUBPAY_STATUS_COMPLETED,
  HUBPAY_STATUS_CANCELLED
]