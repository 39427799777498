import styled from 'styled-components'
import { colors } from 'Common/constants'

export const PriceWrapper = styled.div`
  margin-left: 16px;
  text-align: right;
`
export const GrossPrice = styled.h3`
  color: ${colors.grey4};
  text-decoration: line-through;
  margin: 0;
`
export const FinalPrice = styled.h2`
  color: ${colors.main};
  font-weight: bold;
  margin: 0;
`
