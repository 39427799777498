import React from 'react'
import PropTypes from 'prop-types'
import { List, Avatar } from 'antd'

import { PriceWrapper, GrossPrice, FinalPrice } from './styled'

const ProductsList = props => {
  const { products, device } = props
  return (
    <List
      dataSource={products}
      renderItem={product => {
        const {
          productId,
          productLabel,
          imageUrl,
          quantity,
          grossTotalPrice,
          finalTotalPrice,
        } = product
        const hasDiscount = grossTotalPrice !== finalTotalPrice
        return (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  shape={'square'}
                  src={imageUrl}
                  size={device === 'desktop' ? 90 : 'large'}
                />
              }
              title={productLabel}
              description={`Ref. ${productId}`}
            />
            <PriceWrapper>
              <p>{`Qtà ${quantity}`}</p>
              {hasDiscount ? (
                <GrossPrice>{`${grossTotalPrice?.toFixed(2)}€`}</GrossPrice>
              ) : null}
              <FinalPrice>{`${finalTotalPrice?.toFixed(2)}€`}</FinalPrice>
            </PriceWrapper>
          </List.Item>
        )
      }}
    />
  )
}
ProductsList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
}
export default ProductsList
