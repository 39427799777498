import styled from 'styled-components'
import { colors } from 'Common/constants'

export const TotalWrapper = styled.div`
  border-top: 1px solid ${colors.grey2};
  padding-top: 16px;
  display: grid;
  gap: 4px;
  & > * {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-self: end;
    width: ${props => (props.device === 'mobile' ? '100%' : '50%')};
  }
`

export const Subtotal = styled.h3`
  color: ${colors.grey4};
  margin: 0;
  & > strong {
    text-decoration: line-through;
  }
`
export const Discount = styled.p`
  color: ${colors.grey3};
  margin: 0;
`
export const Total = styled.h2`
  color: ${colors.main};
  margin: 0;
`
export const Payment = styled.h2`
  color: ${colors.grey4};
  margin: 0;
`
